.product_detail_page {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 5rem 3rem;
}

.product_detail_left img {
  width: 45vw;
  border-radius: 10px;
  margin-bottom: 2rem;
}

.image_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 45vw;
}

.image_row img {
  width: 100px;
  border-radius: 10px;
}

.product_detail_right h2 {
  font-size: 32px;
}

.product_detail_right p {
  font-size: 14px;
}

.rating {
  font-size: 16px;
  text-decoration: underline;
  margin-left: 1rem;
}

ion-icon {
  color: rgb(211, 181, 7);
  font-size: 22px;
}

.button {
  margin-top: 2rem;
  width: 20vw;
  height: 4rem;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
  background-color: red;
  font-weight: lighter;
}

.button:hover {
  background-color: darkred;
}
