.search_result li {
  padding: 10px;
}

.search_result li:hover {
  background-color: #f7f7f7;
  cursor: pointer;
  font-weight: bold;
}

.search_result {
  /* border: 1px solid black; */
  border-top: none;
}
