header {
  font-size: 1.2rem;
  /* font-weight: bold; */
}

.logo {
  width: 300px;
}

.flex_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  gap: 1rem;
}

.search_container {
  position: relative;
  width: 100%;
  max-width: 500px;
}

.search_container input {
  width: 30vw;
  height: 2.5rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.search_results {
  position: absolute;
  list-style: none;
  padding-left: 1rem;
  background-color: white;
  width: 30vw;
  border: black 1px solid;
  border-top: none;
}

.nav_container {
  display: flex;
  list-style: none;
  gap: 4rem;
  font-size: 20px;
  white-space: nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.merchandise_container {
  display: flex;
  justify-content: space-evenly;
  background-color: black;
  color: white;
  font-size: 1.2rem;
}

.merchandise_container p:hover {
  transition: text-decoration 0.3s;
  cursor: pointer;
  color: #cacaca;
  transition-delay: 0.3s;
}

a {
  text-decoration: none;
  color: rgba(51, 106, 134, 255);
}

a:hover,
a:focus {
  text-decoration: none;
  color: #7397a3;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 4em;
  height: 0.2rem;
  margin: 5px 0;
  background-color: black;
}

.want {
  background-color: rgba(51, 106, 134, 255);
  padding: 1rem;
  font-weight: bold;
  border-radius: 20px;
}

.want a {
  color: white;
}

.want a:hover {
  color: lightgray;
}

@media (max-width: 800px) {
  .logo {
    width: 30vw;
  }

  .flex_container {
    padding: 2rem 1rem;
  }

  .flex_container input {
    width: 75%;
  }

  .search_results {
    width: 75%;
    font-size: 2vw;
  }

  .nav_container {
    background-color: black;
    color: white;
    flex-direction: column;
    width: 100%;
    padding: 3rem 0rem;
    position: absolute;
    top: 139.5px;
    z-index: 1;
    right: 0;
  }

  .merchandise_container {
    font-size: 2vw;
  }

  .hamburger {
    display: flex;
    /* width: 1vw; */
  }
  .close {
    display: none;
  }
}
