/* .about_page {
  font-size: 22px;
}

.about_page h1 {
  font-size: 60px;
  margin: 0;
}

.about_page h2 {
  text-align: center;
  font-size: 40px;
}

.about {
  background-color: #efefef;
  padding: 5rem;
}

.expect {
  background-color: white;
  padding: 5rem;
}

@media (max-width: 800px) {
  .about_page {
    font-size: 3vw;
    margin: 0;
  }

  .about_page h1,
  .about_page h2 {
    font-size: 3vw;
  }

  .about_page p {
    font-size: 2vw;
  }

  .expect {
    font-size: 3vw;
  }

  .expect h1 {
    font-size: 3vw;
  }
} */

.about_page {
  font-size: 18px;
  line-height: 1.8;
  color: #333;
  margin: 0;
  padding: 2rem 0;
  border: 1px solid #ddd;
}

.about_page h1 {
  font-size: 3.5rem;
  color: #336a86;
  text-align: center;
  margin-bottom: 1rem;
}

.about_page h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #28546c;
  font-weight: 600;
}

.about {
  background-color: #f8f9fa;
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  max-width: 1000px;
  border: 1px solid #ddd;
}

.expect {
  background-color: #ffffff;
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  max-width: 1000px;
}

.expect h1,
.about h1 {
  font-size: 2.8rem;
  text-align: center;
  color: #336a86;
  margin-bottom: 2rem;
}

.expect h3,
.about_page h3 {
  font-size: 1.5rem;
  color: #28546c;
  margin-bottom: 1rem;
}

.about_page p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #555;
}

.expect h4 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .about_page {
    font-size: 16px;
  }

  .about_page h1 {
    font-size: 2.5rem;
  }

  .about_page h2 {
    font-size: 2rem;
  }

  .about_page p {
    font-size: 1rem;
  }

  .expect h1 {
    font-size: 2.2rem;
  }

  .expect h3 {
    font-size: 1.2rem;
  }

  .expect h4 {
    font-size: 1rem;
  }
}
