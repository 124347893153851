.flex_items {
  display: flex;
  flex-direction: column;
}

.flex_items p {
  font-size: 1rem;
}

.flex_items img {
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
}

@media (max-width: 800px) {
  .flex_items p {
    font-size: 3vw;
  }
}
