h1 {
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  color: rgba(51, 106, 134, 255);
}

hr {
  width: 70%;
  margin-bottom: 3rem;
}

.home img {
  width: 100%;
}

.flex_container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  list-style: none;
}

.banner {
  display: block;
  margin: auto;
}
/* 
.trending {
  background-color: rgb(245 245 245);
} */
.featured_items {
  background-color: rgb(245 245 245);
}

@media (max-width: 800px) {
  h1 {
    font-size: 5vw;
  }
}
