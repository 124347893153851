/* Sidebar.module.css */

.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
}

.sidebar h3 {
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #444;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  margin: 10px 0;
  padding: 8px 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.sidebar ul li:hover {
  background-color: #e9ecef;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.sidebar ul li:active {
  background-color: #dee2e6;
}

.sidebar ul li.selected {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  border-color: #0056b3;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
