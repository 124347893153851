/* Jobs Container */
.jobs_container {
  text-align: center;
  align-items: center;
  max-width: 800px; /* Limit width for better readability */
  margin: 3rem auto;
  padding: 3rem;
  border: 1px solid #ddd;
  border-radius: 15px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Title and Description */
.jobs_container h1 {
  font-size: 3rem;
  color: #336a86;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.span_paragraph {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
}

/* Label and Input Section */
.label_input {
  font-size: 1rem;
  text-align: left;
  margin-bottom: 2rem;
  width: 100%;
}

.label_input p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.label_input input,
.label_input textarea {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.label_input input:focus,
.label_input textarea:focus {
  border-color: #336a86;
  box-shadow: 0 0 5px rgba(51, 106, 134, 0.5);
  outline: none;
}

.label_input textarea {
  min-height: 5rem;
  resize: vertical;
}

/* Submit Button */
.button {
  width: 100%;
  height: 3.5rem;
  font-size: 1.2rem;
  color: white;
  background-color: #336a86;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.button:hover {
  background-color: #28546c;
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .jobs_container {
    padding: 2rem;
  }

  .label_input input,
  .label_input textarea {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .button {
    height: 3rem;
    font-size: 1rem;
  }
}
